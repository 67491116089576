import React from 'react';
import { faker } from '@faker-js/faker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

const Departments = () => {
  const departments = [
    {
      name: 'General Affairs',
      contents: [
        'Standard Operation Policies & Procedures',
        'Compliance News',
        'Code of Conduct/Reference Case',
        'Admin & Security Services'
      ]
    },
    {
      name: 'Human Resource',
      contents: [
        faker.name.jobTitle(),
        faker.name.jobType(),
        faker.name.jobType(),
        faker.name.jobArea()
      ]
    },
    {
      name: 'Sales Division',
      contents: [
        faker.name.jobTitle(),
        faker.name.jobType(),
        faker.name.jobType(),
        faker.name.jobArea()
      ]
    },
    {
      name: 'Product and Training',
      contents: [
        faker.name.jobTitle(),
        faker.name.jobType(),
        faker.name.jobType(),
        faker.name.jobArea()
      ]
    }
  ];

  return (
    <div className='content mt-4'>
      <Row>
        <Col md='6'>
          <Row>
            <Col>
              <Input className='mb-3' type='select'>
                <option>General Affairs</option>
              </Input>
            </Col>
            <Col md='auto'>
              <Button color='info' className='mt-0'>
                {faker.finance.amount(1, 99)} Files
              </Button>
            </Col>
            <Col>
              <form>
                <InputGroup>
                  <Input placeholder='Search...' />
                  <InputGroupAddon addonType='append'>
                    <InputGroupText>
                      <i className='nc-icon nc-zoom-split' />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md='8'>
          {departments.map((department) => (
            <>
              <Row>
                <Col>
                  <h4>{department.name}</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  {department.contents.map((content) => (
                    <Button
                      color='neutral'
                      className='w-100 shadow-sm rounded text-left p-3 mb-0'>
                      {content}
                      <FontAwesomeIcon
                        icon='fa-solid fa-chevron-down'
                        className='float-right'
                      />
                    </Button>
                  ))}
                </Col>
              </Row>
            </>
          ))}
        </Col>
        <Col md='4'>
          <h4>Activity Feed</h4>
        </Col>
      </Row>
    </div>
  );
};

export default Departments;
