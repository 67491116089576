import React from 'react';
import { faker } from '@faker-js/faker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardTitle,
  CardImgOverlay,
  CardImg,
  CardText,
  Row,
  Col,
  Button
} from 'reactstrap';

const Dashboard = () => (
  <div className='content mt-4'>
    <Row>
      <Col>
        <h4 className='mt-0'>News and Announcements</h4>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card inverse>
          <CardImg
            alt='Card image cap'
            src='https://picsum.photos/300/170'
            width='100%'
          />
          <CardImgOverlay className='d-flex align-items-end'>
            <CardText>{faker.lorem.lines(2)}</CardText>
          </CardImgOverlay>
        </Card>
      </Col>
      <Col>
        <Card inverse>
          <CardImg
            alt='Card image cap'
            src='https://picsum.photos/301/170'
            width='100%'
          />
          <CardImgOverlay className='d-flex align-items-end'>
            <CardText>{faker.lorem.lines(2)}</CardText>
          </CardImgOverlay>
        </Card>
      </Col>
      <Col>
        <Card inverse>
          <CardImg
            alt='Card image cap'
            src='https://picsum.photos/302/170'
            width='100%'
          />
          <CardImgOverlay className='d-flex align-items-end'>
            <CardText>{faker.lorem.lines(2)}</CardText>
          </CardImgOverlay>
        </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <h4 className='mt-0'>President</h4>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card inverse>
          <CardImg
            alt='Card image cap'
            src='https://picsum.photos/1000/150'
            width='100%'
          />
          <CardImgOverlay>
            <CardTitle tag='h5'>{faker.lorem.lines(1)}</CardTitle>
            <CardText>{faker.lorem.lines(3)}</CardText>
          </CardImgOverlay>
        </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <h4 className='mt-0'>Message & Policy</h4>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card inverse>
          <CardImg
            alt='Card image cap'
            src='https://picsum.photos/500/150'
            width='100%'
          />
        </Card>
      </Col>
      <Col>{faker.lorem.lines(4)}</Col>
    </Row>

    <Row>
      <Col>
        <h4 className='mt-0'>Corporate Ethics</h4>
      </Col>
    </Row>
    <Row className='mb-3'>
      <Col>{faker.lorem.paragraph(10)}</Col>
    </Row>

    <Row>
      <Col>
        <h4 className='mt-0'>Links</h4>
      </Col>
    </Row>
    <Row>
      <Col>
        <Row className='pt-3'>
          <Col>
            <FontAwesomeIcon
              icon='fa-brands fa-facebook-square'
              className='text-info mr-3'
              size='lg'
            />
            Facebook
          </Col>
          <Col>
            <FontAwesomeIcon
              icon='fa-brands fa-youtube'
              className='text-info mr-3'
              size='lg'
            />
            Youtube
          </Col>
        </Row>
        <Row className='pt-1'>
          <Col>
            <FontAwesomeIcon
              icon='fa-solid fa-globe'
              className='text-info mr-3'
              size='lg'
            />
            Web
          </Col>
          <Col>
            <FontAwesomeIcon
              icon='fa-brands fa-instagram'
              className='text-info mr-3'
              size='lg'
            />
            Instagram
          </Col>
        </Row>
      </Col>
      <Col>
        <Button color='info' size='lg'>
          Go to we, Daikin
        </Button>
      </Col>
    </Row>
  </div>
);

export default Dashboard;
