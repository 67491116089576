import React from 'react';
import { faker } from '@faker-js/faker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Card,
  CardImgOverlay,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button
} from 'reactstrap';

const Products = () => {
  const products = new Array(10).fill().map(() => ({
    name: faker.commerce.product(),
    startDate: `${faker.date.past()}`.substring(3,15),
    endDate: `${faker.date.future()}`.substring(3,15),
    image: faker.image.technics()
  }));

  console.log(products);

  return (
    <div className='content mt-4'>
      <Row>
        <Col>
          <Card inverse>
            <CardImg
              alt='Card image cap'
              src='https://picsum.photos/300/170'
              width='100%'
            />
            <CardImgOverlay className='d-flex align-items-end'>
              <CardText>{faker.lorem.lines(2)}</CardText>
            </CardImgOverlay>
          </Card>
        </Col>
      </Row>
      <Row>
        {products.map((product) => (
          <Col lg={3} key={`${product.name}${product.startDate}`}>
            <Card>
              <img alt={product.name} src={product.image} />
              <CardBody>
                <CardTitle tag='h5'>{product.name}</CardTitle>
                <CardSubtitle
                  className='mb-2 text-muted'
                  tag='h6'></CardSubtitle>
                <CardText>
                  Period {product.startDate} - {product.endDate}
                </CardText>
                <Button className='w-100'>See Details</Button>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Products;
