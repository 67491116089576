import React, { useEffect, useRef } from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch, useLocation } from 'react-router-dom';

import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar.js';

import routes from 'routes.js';

var ps;

function Dashboard(props) {
  const mainPanel = useRef();
  const location = useLocation();

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  });

  useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <div className='wrapper'>
      <Sidebar {...props} routes={routes} bgColor='white' activeColor='info' />
      <div className='main-panel' ref={mainPanel}>
        <Header {...props} />
        <Switch>
          {routes.map((prop, key) => (
            <Route path={prop.layout + prop.path} component={prop.component} key={key} />
          ))}
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;
