import React from 'react';
import { faker } from '@faker-js/faker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table
} from 'reactstrap';

import style from './Directory.module.css';

const Directory = () => {
  const users = Array(10)
    .fill()
    .map(() => {
      const name = faker.name.findName();
      return {
        name,
        branch: faker.address.city(),
        contact: faker.phone.number(),
        email: faker.internet.exampleEmail(name)
      };
    });

  return (
    <div className='content mt-4'>
      <Row>
        <Col>
          <h4 className='m-0'>Directory</h4>
        </Col>
        <Col md='auto'>
          <Button color='info' className='mt-0'>
            {faker.finance.amount(1, 99)} Files
          </Button>
        </Col>
        <Col>
          <Input className='mb-3' type='select'>
            <option>General Affairs</option>
          </Input>
        </Col>
        <Col>
          <form>
            <InputGroup>
              <Input placeholder='Search...' />
              <InputGroupAddon addonType='append'>
                <InputGroupText>
                  <i className='nc-icon nc-zoom-split' />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </form>
        </Col>
        <Col md='auto'>
          <Button color='dark' outline className='mt-0'>
            <FontAwesomeIcon icon='fa-solid fa-plus' /> Add a contact
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive className={style.dTable}>
            <thead className='text-primary'>
              <th>Name</th>
              <th>Branch</th>
              <th>Contact No.</th>
              <th>Email Address</th>
              <th>Branch</th>
              <th></th>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr className={style.tr}>
                  <td>
                    <img
                      alt='...'
                      className='border-gray rounded-circle mr-2'
                      style={{ width: '32px', height: '32px' }}
                      src={faker.image.avatar()}
                    />
                    {user.name}
                  </td>
                  <td>{user.branch}</td>
                  <td>{user.contact}</td>
                  <td>{user.email}</td>
                  <td>
                    <FontAwesomeIcon icon='fa-solid fa-trash' /> Remove
                    <FontAwesomeIcon icon='fa-solid fa-pen' /> Edit
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default Directory;
