import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  NavbarToggler,
  NavbarBrand,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input
} from 'reactstrap';

import logo from 'assets/img/logo.png';
import style from './Header.module.css';

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState('transparent');
  const sidebarToggle = useRef();
  const location = useLocation();
  const toggle = () => {
    if (isOpen) {
      setColor('transparent');
    } else {
      setColor('dark');
    }
    setIsOpen(!isOpen);
  };

  const openSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    sidebarToggle.current.classList.toggle('toggled');
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor('dark');
    } else {
      setColor('transparent');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateColor.bind(this));
  });

  useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      sidebarToggle.current.classList.toggle('toggled');
    }
  }, [location]);

  return (
    <>
      <Navbar
        color={
          props.location.pathname.indexOf('full-screen-maps') !== -1
            ? 'dark'
            : color
        }
        expand='lg'
        className={style.gradientHeader}>
        <Container fluid>
          <div className='navbar-wrapper'>
            <div className='navbar-toggle'>
              <button
                type='button'
                ref={sidebarToggle}
                className='navbar-toggler'
                onClick={() => openSidebar()}>
                <span className='navbar-toggler-bar bar1' />
                <span className='navbar-toggler-bar bar2' />
                <span className='navbar-toggler-bar bar3' />
              </button>
            </div>
            <NavbarBrand href='/'>
              <img src={logo} alt='Daikin Logo' className={style.logo} />
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={toggle}>
            <span className='navbar-toggler-bar navbar-kebab' />
            <span className='navbar-toggler-bar navbar-kebab' />
            <span className='navbar-toggler-bar navbar-kebab' />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className='justify-content-end'>
            <form>
              <InputGroup>
                <Input placeholder='Search...' />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>
                    <i className='nc-icon nc-zoom-split' />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form>
          </Collapse>
        </Container>
      </Navbar>
      <Navbar className='bg-white shadow-sm' expand='md'>
        <NavbarToggler onClick={function noRefCheck() {}} />
        <Collapse navbar className='justify-content-center'>
          <Nav className={`me-auto ${style.submenuHeader}`} navbar>
            <NavItem>
              <NavLink href='/admin/dashboard/home'>Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='/admin/dashboard/departments'>Departments</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='/admin/dashboard/directory'>Directory</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='/admin/dashboard/products'>Products</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='/admin/dashboard/promotions'>Promotions</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='/admin/dashboard/forms'>Forms</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='/admin/dashboard/sitemap'>Site Map</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default Header;
