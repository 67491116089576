import { Container, Row, Col, Button, Input } from 'reactstrap';

import logo from '../assets/img/logo.png';

const Register = () => {
  return (
    <div className='wrapper'>
      <Container>
        <Row className='mt-4'>
          <Col>
            <img
              src={logo}
              alt='logo'
              style={{ width: 'auto', height: '30px' }}
            />
          </Col>
          <Col className='text-right'>
            <Button
              color='info'
              onClick={() => window.location.replace('/login')}>
              Login
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '155px' }}>
          <Col md={{ offset: 4, size: 4 }}>
            <h3>Register</h3>
            <Row>
              <Col>
                <Input placeholder='User/Email' />
                <Input placeholder='Password' />
                <Input placeholder='Confirm Password' />
              </Col>
            </Row>
            <Row>
              <Col className='text-right'>
                <Button
                  color='info'
                  className='w-100'
                  onClick={() => {
                    window.location.replace('/login');
                  }}>
                  Create Account
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
