import Dashboard from 'views/Dashboard.js';
import Notifications from 'views/Notifications.js';
import Icons from 'views/Icons.js';
import Typography from 'views/Typography.js';
import TableList from 'views/Tables.js';
import Maps from 'views/Map.js';
import UserPage from 'views/User.js';
import UpgradeToPro from 'views/Upgrade.js';

import Departments from 'views/Departments';
import Directory from 'views/Directory';
import Products from 'views/Products';

var routes = [

  // Extra
  {
    hide: true,
    path: '/dashboard/departments',
    name: 'Departments',
    component: Departments,
    layout: '/admin'
  },
  {
    hide: true,
    path: '/dashboard/directory',
    name: 'Directory',
    component: Directory,
    layout: '/admin'
  },
  {
    hide: true,
    path: '/dashboard/departments',
    name: 'Directory',
    component: Departments,
    layout: '/admin'
  },
  {
    hide: true,
    path: '/dashboard/products',
    name: 'Products',
    component: Products,
    layout: '/admin'
  },
 // Show on sidebar
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-layout-11',
    component: Dashboard,
    layout: '/admin'
  },
  {
    path: '/activity',
    name: 'My Activity',
    icon: 'nc-icon nc-paper',
    component: Dashboard,
    layout: '/admin'
  },
  {
    path: '/calendar',
    name: 'Calendar',
    icon: 'nc-icon nc-calendar-60',
    component: Dashboard,
    layout: '/admin'
  },
  {
    path: '/help',
    name: 'Help Center',
    icon: 'nc-icon nc-alert-circle-i',
    component: Dashboard,
    layout: '/admin'
  },
  // {
  //   path: '/signout',
  //   name: 'Sign Out',
  //   icon: 'nc-icon nc-button-power',
  //   component: Dashboard,
  //   layout: '/admin'
  // },
// To delete
  {
    hide: true,
    path: '/icons',
    name: 'Icons',
    icon: 'nc-icon nc-diamond',
    component: Icons,
    layout: '/admin'
  },
  {
    hide: true,
    path: '/maps',
    name: 'Maps',
    icon: 'nc-icon nc-pin-3',
    component: Maps,
    layout: '/admin'
  },
  {
    hide: true,
    path: '/notifications',
    name: 'Notifications',
    icon: 'nc-icon nc-bell-55',
    component: Notifications,
    layout: '/admin'
  },
  {
    hide: true,
    path: '/user-page',
    name: 'User Profile',
    icon: 'nc-icon nc-single-02',
    component: UserPage,
    layout: '/admin'
  },
  {
    hide: true,
    path: '/tables',
    name: 'Table List',
    icon: 'nc-icon nc-tile-56',
    component: TableList,
    layout: '/admin'
  },
  {
    hide: true,
    path: '/typography',
    name: 'Typography',
    icon: 'nc-icon nc-caps-small',
    component: Typography,
    layout: '/admin'
  },
  {
    hide: true,
    pro: true,
    path: '/upgrade',
    name: 'Upgrade to PRO',
    icon: 'nc-icon nc-spaceship',
    component: UpgradeToPro,
    layout: '/admin'
  }
];
export default routes;
