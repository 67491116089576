import React from 'react';
// javascript plugin used to create scrollbars on windows
import { Container, Row, Col, Button, Input } from 'reactstrap';

import logo from '../assets/img/logo.png';

const Login = (props) => {
  return (
    <div className='wrapper'>
      <Container>
        <Row className='mt-4'>
          <Col>
            <img
              src={logo}
              alt='logo'
              style={{ width: 'auto', height: '30px' }}
            />
          </Col>
          <Col className='text-right'>
            New User?
            <Button
              color='info'
              onClick={() => {
                window.location.replace('/register');
              }}>
              Register
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '155px' }}>
          <Col md={{ offset: 8, size: 4 }}>
            <h6>Welcome Back!</h6>
            <h3>Enter your details below</h3>
            <Row>
              <Col>
                <Input placeholder='User/Email' />
                <Input placeholder='Password' />
              </Col>
            </Row>
            <Row className='my-3'>
              <Col className='text-right'>
                <a href='#pedro'>Forgot Password?</a>
              </Col>
            </Row>
            <Row>
              <Col className='text-right'>
                <Button
                  color='info'
                  style={{ paddingLeft: '55px', paddingRight: '55px' }}
                  onClick={() => {
                    window.location.replace('/admin/dashboard');
                  }}>
                  Login
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
