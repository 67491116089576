import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.3.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'assets/css/jumee.css';

import AdminLayout from 'layouts/Admin.js';
import Login from 'views/Login';
import Register from 'views/Register';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, fab);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/login' render={(props) => <Login {...props} />} />
      <Route path='/register' render={(props) => <Register {...props} />} />
      <Route path='/admin' render={(props) => <AdminLayout {...props} />} />
      <Redirect to='/admin/dashboard' />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
