import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import { faker } from '@faker-js/faker';
import { Nav,Modal,ModalBody,ModalHeader,ModalFooter,Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

var ps;

const Sidebar = ({ location, routes, bgColor, activeColor }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const sidebar = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  });

  return (
    <div
      className='sidebar'
      data-color={bgColor}
      data-active-color={activeColor}>
      <div className='logo text-center p-4'>
        <img
          alt='...'
          className='border-gray rounded-circle'
          style={{ width: '100px', height: '100px' }}
          src={require('assets/img/mike.jpg').default}
        />
        <h5>{faker.name.findName()}</h5>
        <h6 className='small color-secondary'>{faker.name.jobArea()}</h6>
        <h6 className='small color-secondary'>{faker.name.jobTitle()}</h6>
      </div>
      <div className='sidebar-wrapper' ref={sidebar}>
        <Nav>
          {routes
            .filter((route) => !route.hide)
            .map((route, key) => {
              return (
                <li
                  className={
                    activeRoute(route.path) + (route.pro ? ' active-pro' : '')
                  }
                  key={key}>
                  <NavLink
                    to={route.layout + route.path}
                    className='nav-link'
                    activeClassName='active'>
                    {route.iconFa ? (
                      <FontAwesomeIcon
                        icon={route.icon}
                        size='lg'
                        className='ml-2 mr-3'
                      />
                    ) : (
                      <i className={route.icon} />
                    )}
                    {route.name}
                  </NavLink>
                </li>
              );
            })}

          <li>
            <NavLink
              to='#'
              onClick={toggle}
              className='nav-link'
              activeClassName='active'>
              <i className='nc-icon nc-button-power' />
              Sign Out
            </NavLink>
          </li>
        </Nav>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody className='text-center'>
          Are you sure you want to logout?<br />
          All your unsaved data will be lost.
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
          <Button onClick={() => {window.location.replace("/login")}} color='info' className='ml-2'>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Sidebar;
